// import DistanceFilter from './filters/DistanceFilter'
import AccessoriesFilter from './filters/AccessoriesFilter'
import BusinessUnitFilter from './filters/BusinessUnitFilter'
import CategoryFilter, { CategoryTreeFilter } from './filters/CategoryFilter'
// import SellerFilter from './filters/SellerFilter'
import ConditionFilter from './filters/ConditionFilter'
import CosmeticConditionFilter from './filters/CosmeticConditionFilter'
// import MobileGradeFilter from './filters/MobileGradeFilter'
// import LotSizeAndShippingFilter from './filters/LotSizeAndShippingFilter'
import CurrentBidFilter from './filters/CurrentBidFilter'
import FunctionalityFilter from './filters/Functionality'
import InventoryTypeFilter from './filters/InventoryTypeFilter'
import ManufacturerFilter from './filters/ManufacturerFilter'
import NumberOfUnitsFilter from './filters/NumberOfUnitsFilter'
import PackagingConditionFilter from './filters/PackagingConditionFilter'
import ProductTypeFilter from './filters/ProductTypeFilter'
import RetailValueFilter from './filters/RetailValueFilter'
// import RegionFilter from './filters/RegionFilter'
import SellerOtherFilter from './filters/SellerOtherFilter'
import ShipmentTypeFilter from './filters/ShipmentTypeFilter'
import WarehouseLocationFilter from './filters/WarehouseLocationFilter'
import type { AuctionFilterList } from './types'

// TODO: implement distance filter

// Note: Several "Rating" filters were previously implemented, but the code has since been
//       deleted. Some of this old code might be useful if/when we resurrect those filters.
//       The MR that removed the code can be found here:
//       https://gitlab.bstock.io/b-stock/code/three-mp/fe/buyer-portal/-/merge_requests/715

// TODO MULA-2677 Remove `subcategoriesEnabled` when feature flag is removed
export function getAllListingsFilters(
  subcategoriesEnabled: boolean = false
): AuctionFilterList {
  return [
    // DistanceFilter,
    // RegionFilter,
    ...(subcategoriesEnabled ? [CategoryTreeFilter] : [CategoryFilter]),
    //RatingFilter,
    //SellerFilter,
    ConditionFilter,
    PackagingConditionFilter,
    CosmeticConditionFilter,
    InventoryTypeFilter,
    // MobileGradeFilter,
    ManufacturerFilter,
    FunctionalityFilter,
    AccessoriesFilter,
    ShipmentTypeFilter,
    //LotSizeAndShippingFilter,
    WarehouseLocationFilter,
    RetailValueFilter,
    NumberOfUnitsFilter,
    CurrentBidFilter,
  ]
}

// TODO MULA-2677 Remove `subcategoriesEnabled` when feature flag is removed
export function getSellerPageFilters(
  subcategoriesEnabled: boolean = false
): AuctionFilterList {
  return [
    // DistanceFilter,
    // RegionFilter,
    ...(subcategoriesEnabled ? [CategoryTreeFilter] : [CategoryFilter]),
    ConditionFilter,
    PackagingConditionFilter,
    CosmeticConditionFilter,
    InventoryTypeFilter,
    // MobileGradeFilter,
    ManufacturerFilter,
    FunctionalityFilter,
    AccessoriesFilter,
    ProductTypeFilter,
    BusinessUnitFilter,
    ShipmentTypeFilter,
    //LotSizeAndShippingFilter,
    WarehouseLocationFilter,
    RetailValueFilter,
    NumberOfUnitsFilter,
    CurrentBidFilter,
  ]
}

// TODO MULA-2677 Remove `subcategoriesEnabled` when feature flag is removed
export function getAllSellersFilters(
  subcategoriesEnabled: boolean = false
): AuctionFilterList {
  return [
    // DistanceFilter,
    // RegionFilter,
    ...(subcategoriesEnabled ? [CategoryTreeFilter] : [CategoryFilter]),
    PackagingConditionFilter,
    InventoryTypeFilter,
    //RatingFilter,
    //SellerFilter,
    NumberOfUnitsFilter,
    SellerOtherFilter,
  ]
}
