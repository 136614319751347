import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { UnstyledButton } from '@b-stock/bstock-next'
import { EmptyState, FormattedMessage } from '@b-stock/bstock-react'
import {
  breakpoints,
  designColors,
  typography,
} from '@b-stock/bstock-react/theme'

import LoadingAuction from '@components/features/auction/LoadingAuction'
import SearchResultCard from '@components/features/auction/SearchResultCard'
import type {
  SearchResultsPage,
  SearchResult,
} from '@queries/listingSearchQuery'

import { PositionedSearchPagination } from '../AuctionsLayout/shared'
import SortByDropdown from '../SortByDropdown'
import { StyledDropdown } from '../SortByDropdown/SortByDropdown'

type AuctionListProps = {
  openModal: () => void
  searchText: string | null
  searchResults: SearchResultsPage | null
  isMobile?: boolean
  className?: string
  isLoading?: boolean
  showSoldBy?: boolean
  headerMessage?: string // All Auctions
}

export const SwitcherContainer = styled.div`
  display: flex;
  align-self: center;
  grid-gap: 0.5rem;
  justify-content: flex-end;
`

export const FiltersButton = styled(UnstyledButton)`
  justify-self: end;
  padding: 0;
  width: 2.25rem;
  height: 2.25rem;
`

export const FiltersIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
`

export const ListPreamble = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`

export const SortByWrapper = styled.div`
  position: relative;
  @media ${breakpoints.max.medium} {
    display: none;
  }
`

export const ResultCount = styled.span`
  ${typography.subtitle1};
  flex: 1;
  font-size: 1.25rem;
  color: ${designColors.neutral.black};
  display: block;
  align-self: center;
  @media ${breakpoints.max.medium} {
    ${typography.subtitle1}
  }
`

export const GridList = styled.ol`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 3rem 0;
  gap: 3rem 1.5rem;
  @media ${breakpoints.max.medium} {
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
  }
`

export const RowList = styled(GridList)`
  &&& {
    flex-wrap: nowrap;
    flex-direction: column;

    & > li {
      width: 100%;
    }
  }
`

export const SDropdown = styled(StyledDropdown)`
  width: 152px;

  @media ${breakpoints.max.medium} {
    display: none;
  }
`

export const AuctionListPagination = styled(PositionedSearchPagination)`
  @media ${breakpoints.max.medium} {
    display: none;
  }
`

const AuctionList = ({
  openModal,
  isMobile,
  className,
  searchText,
  searchResults,
  showSoldBy = true,
  isLoading = false,
}: AuctionListProps) => (
  <div className={className}>
    <ListPreamble>
      <ResultCount data-testid="ResultsCount">
        {searchResults && (
          <FormattedMessage
            id="AuctionList.resultsCount"
            values={{ count: searchResults.total }}
          />
        )}
      </ResultCount>

      <SortByWrapper>
        <SortByDropdown />
      </SortByWrapper>

      <SwitcherContainer>
        {isMobile && (
          <FiltersButton onClick={openModal}>
            <FiltersIcon icon={faSlidersH} />
          </FiltersButton>
        )}
      </SwitcherContainer>
    </ListPreamble>

    {!isLoading && !searchResults?.total ? (
      <EmptyState
        icon={faSearch}
        title={
          searchText ? (
            <FormattedMessage
              id="AuctionList.noResults"
              values={{ searchText }}
            />
          ) : (
            <FormattedMessage id="AuctionList.noResultsNoSearch" />
          )
        }
      />
    ) : (
      <GridList data-testid="auction-grid-list">
        {isLoading
          ? new Array(24).fill(1).map((_, idx) => (
              <li key={idx}>
                <LoadingAuction animationAllowed />
              </li>
            ))
          : null}

        {searchResults?.total
          ? searchResults.listings.map((result: SearchResult) => (
              <li key={result.listingId}>
                <SearchResultCard result={result} showSoldBy={showSoldBy} />
              </li>
            ))
          : null}
      </GridList>
    )}
  </div>
)

export default AuctionList
