import { useMemo, useCallback } from 'react'
import type * as React from 'react'

import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import { AuctionSearchSortOrder } from '@b-stock/bstock-next'
import { Dropdown, DetailedDropdown } from '@b-stock/bstock-react'
import { designColors } from '@b-stock/bstock-react/theme'

import { useAuctionSearch } from '@components/AuctionSearchProvider'
import type { AuctionSearchSortBy } from '@components/BaseSearchProvider/types'

export const StyledDropdown = styled(Dropdown)`
  height: 36px;
  div {
    color: ${designColors.neutral.darkGray};
  }
  select {
    padding: 6px;
    padding-right: 2.5rem;
  }
  @media (max-width: 576px) {
    * {
      font-size: 0.875rem;
    }
  }
  @media (max-width: 558px) {
    * {
      font-size: 0.875rem;
    }
  }
` as typeof Dropdown

type SortProduct = [AuctionSearchSortBy, AuctionSearchSortOrder]

const prod2Key = (p: SortProduct): string => `${p[0]}&${p[1]}`
// need to throw typesafety out the window here bcause of Dropdown's
// implementation
const key2Prod = (s: string): SortProduct => s.split('&') as SortProduct

const useSortOptions = () => {
  const intl = useIntl()

  return useMemo(() => {
    const usedSortByValues: AuctionSearchSortBy[] = [
      'endTime',
      'startTime',
      'winningBidAmount',
      'numberOfBids',
      'retailPrice',
    ]

    const sortOrderValues: AuctionSearchSortOrder[] = Object.values(
      AuctionSearchSortOrder
    )

    const options: {
      label: string
      value: string
      labelSelected?: React.ReactNode
    }[] = []

    for (let i = 0; i < usedSortByValues.length; i++) {
      const sortBy = usedSortByValues[i]
      for (let k = 0; k < sortOrderValues.length; k++) {
        const sortOrder = sortOrderValues[k]
        options.push({
          label: intl.formatMessage({
            id: `SortBy.orders.${sortBy}.${sortOrder}`,
          }),
          value: prod2Key([sortBy, sortOrder]),
        })
      }
    }

    return options
  }, [intl])
}

const SortByDropdown: React.FC = () => {
  const options = useSortOptions()
  const {
    state: { sortBy, sortOrder },
    setSort,
  } = useAuctionSearch()

  const onChange = useCallback(
    ({ value }: { value: string | number }) => {
      if (typeof value === 'number') {
        throw new Error('Unexpected number.')
      }

      const [sortBy, sortOrder] = key2Prod(value)
      setSort(sortBy, sortOrder)
    },
    [setSort]
  )

  return (
    <DetailedDropdown
      options={options}
      value={prod2Key([sortBy, sortOrder])}
      onChange={onChange}
      label={'Sort by'}
      dropdownMaxHeight={600}
      labelToLeft
    />
  )
}

export default SortByDropdown
