import { useMemo } from 'react'
import type * as React from 'react'

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { styled } from 'styled-components'

import { useDeviceInfo, Layout } from '@b-stock/bstock-next'
import { designColors } from '@b-stock/bstock-react/theme'

type ItemProps =
  | { itemType: 'first'; page: number; disabled: boolean }
  | { itemType: 'previous'; page: number; disabled: boolean }
  | { itemType: 'next'; page: number; disabled: boolean }
  | { itemType: 'last'; page: number; disabled: boolean }
  | { itemType: 'page'; page: number; current: boolean }
  | { itemType: 'spacer' }

type PaginationProps = {
  ItemComponent: React.FC<ItemProps>
  currentPage: number
  pageCount: number
  className?: string
}

const getPagesForPresentation = (
  currentPage: number,
  pageCount: number,
  paddingFactor = 2
) => {
  const strideStart = Math.max(currentPage - paddingFactor, 2)
  const strideEnd = Math.min(currentPage + paddingFactor, pageCount - 1)

  const pages: number[] = [1]

  if (strideStart !== 2) {
    pages.push(-1)
  }

  for (let i = strideStart; i <= strideEnd; i++) {
    pages.push(i)
  }

  if (strideEnd !== pageCount - 1) {
    pages.push(-2)
  }

  if (pages[pages.length - 1] !== pageCount) {
    pages.push(pageCount)
  }

  return pages
}

const baseItemStyles = `
  display: block;
  height: 36px;
  min-width: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ItemAnchor = styled.a<{ $current?: boolean }>`
  ${baseItemStyles}

  color: ${(o) =>
    o.$current ? designColors.neutral.black : designColors.primary.default};

  &:hover {
    background-color: ${(o) =>
      o.$current
        ? designColors.neutral.white
        : designColors.primary.backgroundLightBlue};
  }
`

const Spacer = styled.div`
  ${baseItemStyles}
`

const ExtentAnchor = styled.a<{ $disabled: boolean }>`
  ${baseItemStyles}

  color: ${(o) =>
    o.$disabled ? designColors.neutral.black : designColors.primary.default};
  pointer-events: ${(o) => o.$disabled && 'none'};

  &:hover {
    background-color: ${(o) =>
      o.$disabled
        ? designColors.neutral.white
        : designColors.primary.backgroundLightBlue};
  }
`

const makeItemComponent = (
  linkForPage: (page: number) => string,
  onClick?: (page: number) => void
) => {
  const internalOnClick =
    (page: number) => (e: React.MouseEvent<HTMLElement>) => {
      if (onClick) {
        onClick(page)
        window.scrollTo(0, 0)
        e.preventDefault()
      }
    }

  const ItemComponent: React.FC<ItemProps> = (item) => {
    switch (item.itemType) {
      case 'page':
        return item.current ? (
          <ItemAnchor $current>{item.page}</ItemAnchor>
        ) : (
          <Link href={linkForPage(item.page)} passHref legacyBehavior>
            <ItemAnchor onClick={internalOnClick(item.page)}>
              {item.page}
            </ItemAnchor>
          </Link>
        )
      case 'first':
        return (
          <Link href={linkForPage(item.page)} passHref legacyBehavior>
            <ExtentAnchor
              $disabled={item.disabled}
              onClick={internalOnClick(item.page)}
            >
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </ExtentAnchor>
          </Link>
        )
      case 'previous':
        return (
          <Link href={linkForPage(item.page)} passHref legacyBehavior>
            <ExtentAnchor
              $disabled={item.disabled}
              onClick={internalOnClick(item.page)}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </ExtentAnchor>
          </Link>
        )
      case 'last':
        return (
          <Link href={linkForPage(item.page)} passHref legacyBehavior>
            <ExtentAnchor
              $disabled={item.disabled}
              onClick={internalOnClick(item.page)}
            >
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </ExtentAnchor>
          </Link>
        )
      case 'next':
        return (
          <Link href={linkForPage(item.page)} passHref legacyBehavior>
            <ExtentAnchor
              $disabled={item.disabled}
              onClick={internalOnClick(item.page)}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </ExtentAnchor>
          </Link>
        )
      case 'spacer':
        return <Spacer>{'\u2026'}</Spacer>
    }
  }

  return ItemComponent
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PageList = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 0.5rem;
`

const PageNOfK = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
`

const DesktopPagination: React.FC<PaginationProps> = ({
  currentPage,
  pageCount,
  ItemComponent,
}) => {
  const pages = useMemo(
    () => getPagesForPresentation(currentPage, pageCount),
    [currentPage, pageCount]
  )

  return (
    <>
      <ItemComponent
        itemType="previous"
        page={currentPage - 1}
        disabled={currentPage === 1}
      />
      <PageList>
        {pages.map((pageIdx) =>
          pageIdx > 0 ? (
            <li key={pageIdx}>
              <ItemComponent
                itemType="page"
                page={pageIdx}
                current={pageIdx === currentPage}
              />
            </li>
          ) : (
            <li key={pageIdx}>
              <ItemComponent itemType="spacer" />
            </li>
          )
        )}
      </PageList>
      <ItemComponent
        itemType="next"
        page={currentPage + 1}
        disabled={currentPage === pageCount}
      />
    </>
  )
}

const MobilePagination: React.FC<PaginationProps> = ({
  currentPage,
  pageCount,
  ItemComponent,
}) => {
  return (
    <>
      <ItemComponent itemType="first" page={1} disabled={currentPage === 1} />
      <ItemComponent
        itemType="previous"
        page={currentPage - 1}
        disabled={currentPage === 1}
      />
      <PageNOfK>{`${currentPage} of ${pageCount}`}</PageNOfK>
      <ItemComponent
        itemType="next"
        page={currentPage + 1}
        disabled={currentPage === pageCount}
      />
      <ItemComponent
        itemType="last"
        page={pageCount}
        disabled={currentPage === pageCount}
      />
    </>
  )
}

/**
 * @deprecated Use the Pagination component from bstock-react
 */
const Pagination: React.FC<PaginationProps> = (props) => {
  const { layout } = useDeviceInfo()

  return (
    <Container className={props.className} data-testid="Pagination">
      {layout === Layout.WIDE ? (
        <DesktopPagination {...props} />
      ) : (
        <MobilePagination {...props} />
      )}
    </Container>
  )
}

export default Pagination

export { getPagesForPresentation, makeItemComponent }
export type { PaginationProps }
